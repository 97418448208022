import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-6">
          Voorwaarden en Condities
        </h1>
        <p className="mb-6 text-gray-700">
          Deze voorwaarden en condities schetsen de regels en voorschriften voor
          het gebruik van onze website en diensten.
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 1 - Definities
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Moonlight Photography: Moonlight Photography, gevestigd te Bergen
              op Zoom.
            </li>
            <li>
              Klant: degene met wie Moonlight Photography een overeenkomst is
              aangegaan.
            </li>
            <li>Partijen: Moonlight Photography en Klant samen.</li>
            <li>
              Consument: een Klant die tevens een individu is en die als
              privépersoon handelt.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 2 - Toepasselijkheid
          </h2>

          <ul className="list-disc list-inside text-gray-700">
            <li>
              Deze voorwaarden zijn van toepassing op alle offertes,
              aanbiedingen, werkzaamheden, bestellingen, overeenkomsten en
              leveringen van diensten of producten door of namens Moonlight
              Photography.
            </li>
            <li>
              Moonlight Photography en de Klant kunnen alleen afwijken van deze
              voorwaarden als dat schriftelijk is afgesproken.
            </li>
            <li>
              Moonlight Photography en de Klant sluiten de toepasselijkheid van
              de algemene voorwaarden van de Klant of van anderen uitdrukkelijk
              uit.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 3 - Prijzen
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Moonlight Photography hanteert prijzen in euro’s, inclusief btw en
              exclusief eventuele overige kosten zoals administratie- of
              verzendkosten, tenzij schriftelijk anders is afgesproken.
            </li>

            <li>
              Moonlight Photography mag de prijzen van zijn diensten en
              producten op zijn website en in andere uitingen altijd wijzigen.
            </li>
            <li>
              Moonlight Photography en de Klant spreken voor een dienstverlening
              door Moonlight Photography een totaalbedrag als richtprijs af,
              tenzij schriftelijk anders is afgesproken.
            </li>
            <li>
              Moonlight Photography mag tot 10% van de richtprijs afwijken. -
              Moonlight Photography moet de Klant op tijd laten weten waarom een
              hogere prijs gerechtvaardigd is, wanneer de richtprijs meer dan
              10% hoger uit gaat vallen.
            </li>
            <li>
              De Klant mag het deel van de opdracht dat boven de richtprijs
              (vermeerderd met 10%) uitkomt laten vervallen, wanneer de
              richtprijs meer dan 10% hoger uit gaat vallen.
            </li>
            <li>Moonlight Photography mag de prijzen jaarlijks aanpassen.</li>
            <li>
              Moonlight Photography zal prijsaanpassingen meedelen aan de Klant
              voorafgaand aan de ingang ervan.
            </li>
            <li>
              Een consument mag de overeenkomst met Moonlight Photography
              ongedaan maken wanneer hij het niet eens is met de prijsverhoging.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 4 - Betalingen en betalingstermijn
          </h2>

          <ul className="list-disc list-inside text-gray-700">
            <li>
              Moonlight Photography mag bij het aangaan van de overeenkomst een
              aanbetaling tot 50% van het afgesproken bedrag verlangen.
            </li>
            <li>
              De Klant moet een betaling op de dag van levering hebben voldaan.
            </li>
            <li>
              De betalingstermijnen die Moonlight Photography hanteert, zijn
              fatale betalingstermijnen.
            </li>
            <li>
              Dat betekent dat indien de Klant het afgesproken bedrag niet
              uiterlijk op de laatste dag van de betalingstermijn heeft betaald,
              hij automatisch in verzuim en in gebreke is, zonder dat Moonlight
              Photography aan de Klant een aanmaning hoeft te sturen of in
              gebreke hoeft te stellen.
            </li>
            <li>
              Moonlight Photography mag een levering afhankelijk stellen van
              onmiddellijke betaling dan wel een zekerheidstelling eisen voor
              het totale bedrag van de diensten of producten.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 5 - Annuleren/Retourneren
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Klant heeft recht om een reservering binnen 14 dagen te annuleren,
              tenzij er geen aanbetaling is gedaan.
            </li>
            <li>
              Nadat een klant heeft aanbetaald, is annuleren wel mogelijk.
            </li>
            <li>
              De aanbetaling zal gebruikt worden als vergoeding voor het
              reserveren van de afgesproken datum en tijd.
            </li>
            <li>Deze wordt dus niet terugbetaald.</li>
            <li>
              Klant heeft geen recht op het retourneren van de producten die op
              maat gemaakt of gepersonaliseerd zijn.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 6 - Eigendomsvoorbehoud
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Moonlight Photography blijft eigenaar van alle geleverde producten
              totdat de Klant alle openstaande facturen van Moonlight
              Photography met betrekking tot een onderliggende overeenkomst
              heeft betaald, inclusief vorderingen vanwege het tekortschieten in
              de nakoming.
            </li>
            <li>
              Tot die tijd in lid 1 kan Moonlight Photography gebruikmaken van
              zijn eigendomsvoorbehoud en de zaken terugnemen.
            </li>
            <li>
              Voordat het eigendom is overgegaan op de Klant, mag de Klant de
              producten niet verpanden, verkopen, vervreemden of op een andere
              manier bezwaren.
            </li>
            <li>
              Wanneer Moonlight Photography gebruik maakt van zijn
              eigendomsvoorbehoud, dan wordt daarmee de overeenkomst ongedaan
              gemaakt en mag Moonlight Photography van de Klant
              schadevergoeding, gederfde winst en rente eisen.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 7 - Levering
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Levering vindt plaats bij Moonlight Photography, tenzij anders
              wordt afgesproken.
            </li>
            <li>
              Levering van online bestelde producten vindt plaats op het door de
              Klant aangegeven adres.
            </li>
            <li>
              Wanneer de Klant de afgesproken bedragen niet of niet op tijd
              betaalt, mag Moonlight Photography zijn verplichtingen opschorten
              totdat de Klant betaalt.
            </li>
            <li>
              Bij te late betaling is er sprake van schuldeisersverzuim,
              waardoor de Klant een verlate levering niet aan Moonlight
              Photography kan tegenwerpen.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 8 - Levertijd
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              De levertijden van Moonlight Photography zijn indicatief. Indien
              later wordt geleverd, kan de Klant hieraan geen rechten ontlenen,
              tenzij schriftelijk anders wordt afgesproken.
            </li>
            <li>
              De levertijd gaat in wanneer de Klant het bestelproces volledig
              heeft afgerond en daarvan een bevestiging heeft gekregen van
              Moonlight Photography.
            </li>
            <li>
              De Klant krijgt geen schadevergoeding en mag de overeenkomst niet
              ongedaan maken wanneer Moonlight Photography later levert dan is
              afgesproken.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 9 - Verpakking en verzending
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Wanneer de verpakking van een geleverd product geopend of
              beschadigd is, dan moet de Klant hiervan door de vervoerder een
              aantekening op laten maken voordat hij het product in ontvangst
              neemt. Doet de Klant dit niet, dan kan hij Moonlight Photography
              niet aansprakelijk stellen voor eventuele schade.
            </li>
            <li>
              Wanneer de Klant zelf het transport van een product regelt, moet
              hij eventuele zichtbare beschadigingen aan producten of de
              verpakking voorafgaand aan het vervoer melden aan Moonlight
              Photography. Doet de Klant dit niet, dan kan hij Moonlight
              Photography niet aansprakelijk stellen voor eventuele schade.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 10 - Intellectueel eigendom
          </h2>

          <ul className="list-disc list-inside text-gray-700">
            <li>
              Moonlight Photography behoudt alle intellectuele eigendomsrechten
              op alle ontwerpen, tekeningen, geschriften, dragers met gegevens
              of andere informatie, offertes, afbeeldingen, schetsen, modellen
              en maquettes, tenzij anders is afgesproken.
            </li>
            <li>
              De Klant mag de intellectuele eigendomsrechten in lid 1 niet
              zonder voorafgaande schriftelijke toestemming van Moonlight
              Photography aan anderen tonen, ter beschikking stellen of op een
              andere manier gebruiken.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 11 - Boetebeding
          </h2>

          <ul className="list-disc list-inside text-gray-700">
            <li>
              Bij niet tijdig afzeggen van de afspraak, zal de consument een
              boete betalen van €50,- voor de gemaakte kosten.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 12 - Aansprakelijkheid Klant
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Wanneer Moonlight Photography een overeenkomst aangaat met
              meerdere Klanten, is ieder van hen hoofdelijk aansprakelijk voor
              het nakomen van de afspraken in die overeenkomst.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Artikel 13 - Toepasselijk recht en bevoegde rechter
          </h2>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Op deze algemene voorwaarden en iedere onderliggende overeenkomst
              tussen de Klant en Moonlight Photography is Nederlands recht van
              toepassing.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
