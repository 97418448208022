import React from "react";
import { Image } from "antd";

const loveShootImages = [
  { src: require("../img/shoots/love/M&P-10.jpg"), alt: "Love Shoot 1" },
  { src: require("../img/shoots/love/M&P-15.jpg"), alt: "Love Shoot 2" },
  { src: require("../img/shoots/love/M&P-20.jpg"), alt: "Love Shoot 3" },
  { src: require("../img/shoots/love/M&P-6.jpg"), alt: "Love Shoot 4" },
];

const sabineShootImages = [
  {
    src: require("../img/shoots/sabine/116A4397-2.jpg"),
    alt: "Sabine Shoot 1",
  },
  { src: require("../img/shoots/sabine/116A4409.jpg"), alt: "Sabine Shoot 2" },
  { src: require("../img/shoots/sabine/116A4528.jpg"), alt: "Sabine Shoot 3" },
  { src: require("../img/shoots/sabine/116A4624.jpg"), alt: "Sabine Shoot 4" },
];

const pregnancyShootImages = [
  {
    src: require("../img/shoots/Zwangerschap/Mariah-10.jpg"),
    alt: "Pregnancy Shoot 1",
  },
  {
    src: require("../img/shoots/Zwangerschap/Mariah-14.jpg"),
    alt: "Pregnancy Shoot 2",
  },
  {
    src: require("../img/shoots/Zwangerschap/Mariah-16.jpg"),
    alt: "Pregnancy Shoot 3",
  },
  {
    src: require("../img/shoots/Zwangerschap/Mariah-22.jpg"),
    alt: "Pregnancy Shoot 4",
  },
];

const micheleShootImages = [
  {
    src: require("../img/shoots/Michele/IMG-20240609-WA0004.jpg"),
    alt: "Michele Shoot 1",
  },
  {
    src: require("../img/shoots/Michele/IMG-20240609-WA0005.jpg"),
    alt: "Michele Shoot 2",
  },
  {
    src: require("../img/shoots/Michele/IMG-20240609-WA0006.jpg"),
    alt: "Michele Shoot 3",
  },
  {
    src: require("../img/shoots/Michele/IMG-20240609-WA0007.jpg"),
    alt: "Michele Shoot 4",
  },
];

const eliseShootImages = [
  { src: require("../img/shoots/elise/F-1.jpg"), alt: "Elise Shoot 1" },
  { src: require("../img/shoots/elise/F-11.jpg"), alt: "Elise Shoot 2" },
  { src: require("../img/shoots/elise/F-13.jpg"), alt: "Elise Shoot 3" },
  { src: require("../img/shoots/elise/F-16.jpg"), alt: "Elise Shoot 4" },
];

const danielleShootImages = [
  {
    src: require("../img/shoots/Danielle/LOVE-33.jpg"),
    alt: "Danielle Shoot 1",
  },
  {
    src: require("../img/shoots/Danielle/LOVE-29.jpg"),
    alt: "Danielle Shoot 2",
  },
  {
    src: require("../img/shoots/Danielle/LOVE-26.jpg"),
    alt: "Danielle Shoot 3",
  },
  {
    src: require("../img/shoots/Danielle/LOVE-8.jpg"),
    alt: "Danielle Shoot 4",
  },
];

const familyShootImages = [
  { src: require("../img/shoots/Danielle/F-8.jpg"), alt: "Family Shoot 1" },
  { src: require("../img/shoots/Danielle/F-22.jpg"), alt: "Family Shoot 2" },
  { src: require("../img/shoots/Danielle/F-25.jpg"), alt: "Family Shoot 3" },
  { src: require("../img/shoots/Danielle/F-13.jpg"), alt: "Family Shoot 4" },
];

const loveshoot2Images = [
  { src: require("../img/shoots/Danielle/W-49.jpg"), alt: "Love Shoot 2 - 1" },
  { src: require("../img/shoots/Danielle/W-50.jpg"), alt: "Love Shoot 2 - 2" },
  { src: require("../img/shoots/Danielle/W-18.jpg"), alt: "Love Shoot 2 - 3" },
  { src: require("../img/shoots/Danielle/W-54.jpg"), alt: "Love Shoot 2 - 4" },
];

const pregnancyShoot2Images = [
  {
    src: require("../img/shoots/Danielle/Z-8.jpg"),
    alt: "Pregnancy Shoot 2 - 1",
  },
  {
    src: require("../img/shoots/Danielle/Z-14.jpg"),
    alt: "Pregnancy Shoot 2 - 2",
  },
  {
    src: require("../img/shoots/Danielle/Z-15.jpg"),
    alt: "Pregnancy Shoot 2 - 3",
  },
  {
    src: require("../img/shoots/Danielle/Z-25.jpg"),
    alt: "Pregnancy Shoot 2 - 4",
  },
];

const evelienShootImages = [
  {
    src: require("../img/shoots/Evelien/A-12.jpg"),
    alt: "Evelien Shoot 1",
  },
  {
    src: require("../img/shoots/Evelien/A-2.jpg"),
    alt: "Evelien Shoot 2",
  },
  {
    src: require("../img/shoots/Evelien/A-22.jpg"),
    alt: "Evelien Shoot 3",
  },
  {
    src: require("../img/shoots/Evelien/A-8.jpg"),
    alt: "Evelien Shoot 4",
  },
];

const gioShootImages = [
  {
    src: require("../img/shoots/Gio/Z-16.jpg"),
    alt: "Gio Shoot 1",
  },
  {
    src: require("../img/shoots/Gio/Z-9.jpg"),
    alt: "Gio Shoot 2",
  },
];

const iljaShootImages = [
  {
    src: require("../img/shoots/Ilja/F-14.jpg"),
    alt: "Ilja Shoot 1",
  },
  {
    src: require("../img/shoots/Ilja/F-2.jpg"),
    alt: "Ilja Shoot 2",
  },
  {
    src: require("../img/shoots/Ilja/F-21.jpg"),
    alt: "Ilja Shoot 3",
  },
  {
    src: require("../img/shoots/Ilja/F-4.jpg"),
    alt: "Ilja Shoot 4",
  },
];

const keesShootImages = [
  {
    src: require("../img/shoots/Kees/A-11.jpg"),
    alt: "Kees Shoot 1",
  },
  {
    src: require("../img/shoots/Kees/A-14.jpg"),
    alt: "Kees Shoot 2",
  },
  {
    src: require("../img/shoots/Kees/A-3.jpg"),
    alt: "Kees Shoot 3",
  },
  {
    src: require("../img/shoots/Kees/A-5.jpg"),
    alt: "Kees Shoot 4",
  },
];

const willekeShootImages = [
  {
    src: require("../img/shoots/Willeke/F-1.jpg"),
    alt: "Willeke Shoot 1",
  },
  {
    src: require("../img/shoots/Willeke/F-12.jpg"),
    alt: "Willeke Shoot 2",
  },
  {
    src: require("../img/shoots/Willeke/F-2.jpg"),
    alt: "Willeke Shoot 3",
  },
  {
    src: require("../img/shoots/Willeke/F-9.jpg"),
    alt: "Willeke Shoot 4",
  },
];

// Gallery Component
const Gallery = ({ images }) => {
  return (
    <Image.PreviewGroup>
      {images.map((image, index) => (
        <div key={index}>
          <Image
            className="object-cover object-center rounded-lg"
            src={image.src}
            alt={image.alt}
            height={300}
            width={290}
          />
        </div>
      ))}
    </Image.PreviewGroup>
  );
};

const Portfolio = () => {
  return (
    <div className="container h-full mx-auto">
      <div className="flex items-center justify-center mt-6">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mb-6">
          <Gallery images={loveShootImages} />
          <Gallery images={loveshoot2Images} />
          <Gallery images={danielleShootImages} />
          <Gallery images={sabineShootImages} />
          <Gallery images={pregnancyShootImages} />
          <Gallery images={pregnancyShoot2Images} />
          <Gallery images={micheleShootImages} />
          <Gallery images={eliseShootImages} />
          <Gallery images={familyShootImages} />
          <Gallery images={evelienShootImages} />
          <Gallery images={iljaShootImages} />
          <Gallery images={keesShootImages} />
          <Gallery images={willekeShootImages} />
          <Gallery images={gioShootImages} />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
