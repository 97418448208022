import React from "react";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import Contact from "../components/Contact";

const Home = () => {
  return (
    <>
      <div className="container mx-auto h-full">
        <Portfolio />
        <Services />
        <Contact />
      </div>
    </>
  );
};

export default Home;
