import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { Alert, Select, Form } from "antd";

const ContactForm = () => {
  const [emailResponse, setEmailResponse] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const [category, setCategory] = useState(null);
  const form = useRef();

  const handleClose = () => {
    setEmailResponse(false);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  useEffect(() => {
    console.log(category);
  }, [category]);

  const getServiceId = () => {
    const infoId = {
      service: process.env.REACT_APP_EMAILJS_INFORMATION_SERVICE_ID,
      template: process.env.REACT_APP_EMAILJS_INFORMATION_TEMPLATE_ID,
    };

    const serviceId = {
      service: process.env.REACT_APP_EMAILJS_SERVICE_SERVICE_ID,
      template: process.env.REACT_APP_EMAILJS_SERVICE_TEMPLATE_ID,
    };

    setEmailResponse(false);

    if (!category) {
      setEmailResponse({
        status: "error",
        message: "Geen categorie geselecteerd",
      });
      return;
    }

    if (category === "info") {
      return infoId;
    } else {
      return serviceId;
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const emailjsData = getServiceId();
    if (!emailjsData) return;

    setEmailSending(true);

    emailjs
      .sendForm(emailjsData.service, emailjsData.template, form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      })
      .then(
        () => {
          setEmailResponse({
            status: "success",
            message: "Email succesvol verzonden",
          });

          setEmailSending(false);
          form.current.reset();
        },
        (error) => {
          setEmailResponse({
            status: "error",
            message: error.text,
          });
        }
      );
  };

  return (
    <>
      {emailResponse && (
        <div className="mb-8 mt-8">
          <Alert
            message={emailResponse.message}
            type={emailResponse.status}
            closable
            afterClose={handleClose}
          />
        </div>
      )}

      <form onSubmit={sendEmail} ref={form} className="space-y-8">
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
          >
            Email
          </label>
          <input
            disabled={emailSending}
            type="email"
            id="email"
            name="email"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-600 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
            placeholder="email@hotmail.com"
            required
          />
        </div>
        <div>
          <label
            htmlFor="subject"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
          >
            Onderwerp
          </label>
          <input
            disabled={emailSending}
            type="text"
            id="subject"
            name="subject"
            className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-600 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
            placeholder="Laat ons weten hoe we je kunnen helpen"
            required
          />
        </div>

        <div className="w-full">
          <label
            htmlFor="category"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
          >
            Categorie
          </label>

          <Select
            disabled={emailSending}
            style={{ width: 200 }}
            placeholder="Selecteer een categorie"
            onChange={handleCategoryChange}
            options={[
              {
                value: "info",
                label: "Informatie",
              },
              {
                value: "booking",
                label: "Boeking",
              },
              {
                value: "order",
                label: "Bestelling",
              },
            ]}
          />
        </div>
        <div>
          <label
            htmlFor="phone"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
          >
            Telefoon Nummer
          </label>
          <input
            disabled={emailSending}
            type="tel"
            id="phone"
            name="phone"
            className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-600 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
            placeholder="Je telefoon nummer"
            required
          />
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500"
          >
            Je Bericht
          </label>
          <textarea
            disabled={emailSending}
            id="message"
            name="message"
            rows="6"
            className="block p-2.5 w-full text-sm text-gray-200 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-600 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder="Laat een comment achter"
          ></textarea>
        </div>
        <button
          disabled={emailSending}
          type="submit"
          className="py-3 px-5 text-sm text-center text-white bg-yellow-600 sm:w-fit hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        >
          {emailSending ? "Bericht wordt verzonden..." : "Stuur Bericht"}
        </button>
      </form>
    </>
  );
};

export default ContactForm;
