import React from "react";

import { FaInstagram, FaWhatsapp } from "react-icons/fa";

const Socials = ({ size, color }) => {
  return (
    <div className="flex">
      <a
        href="whatsapp://send?phone=+31642900640"
        className={`mx-2 text-gray-600 transition-colors duration-300 dark:${color} hover:text-blue-500 dark:hover:text-blue-400`}
        aria-label="Whatsapp"
        target="_blank"
      >
        <FaWhatsapp size={size} />
      </a>

      <a
        href="https://www.instagram.com/moonlight._photo?igsh=MTRuMGg1cjQxcTVzNQ=="
        className={`mx-2 text-gray-600 transition-colors duration-300 dark:${color} hover:text-blue-500 dark:hover:text-blue-400`}
        aria-label="Instagram"
        target="_blank"
      >
        <FaInstagram size={size} />
      </a>
    </div>
  );
};

export default Socials;
