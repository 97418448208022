import logo from "../img/header/logo.png";
import { Link as RouterLink } from "react-router-dom";
import { Element } from "react-scroll";
import { scroll } from "../helperFunctions/scroller";
import Salonized from "./salonized";

const Header = () => {
  return (
    <>
      <header className="bg-gray-900 w-full px-6 lg:px-24 z-30 h-[230px] lg:h-[240px] flex flex-col justify-center items-center">
        <Element name="top">
          <div className="py-5 flex flex-col items-center w-full justify-center">
            <RouterLink to="/" className="px-6 absolute max-w-[250px] mt-12">
              <img className="" src={logo} alt="Logo" />
            </RouterLink>
          </div>
          <div className="mt-12 text-yellow-600 flex flex-col text-center font-text">
            <p>Let beautiful memories not remain only in our minds.</p>
          </div>
        </Element>

        <nav className="mt-8 flex gap-4">
          <RouterLink
            className="cursor-pointer bg-yellow-600 hover:bg-yellow-700 text-white py-2 px-4"
            to={"/"}
            onClick={() => scroll("portfolio")}
          >
            Portfolio
          </RouterLink>

          <RouterLink
            className="cursor-pointer bg-yellow-600 hover:bg-yellow-700 text-white py-2 px-4"
            to={"/"}
            onClick={() => scroll("services")}
          >
            Services
          </RouterLink>

          <RouterLink
            className="cursor-pointer bg-yellow-600 hover:bg-yellow-700 text-white py-2 px-4"
            onClick={() => scroll("contact")}
            to={"/"}
          >
            Contact
          </RouterLink>

        </nav>
      </header>


    </>
  );
};

export default Header;
