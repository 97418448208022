import PackageImageFirst from "../img/shoots/love/M&P-15.jpg";
import PackageImageSecond from "../img/cover/comfort-cover.jpeg";
import PackageImageThird from "../img/shoots/Zwangerschap/Mariah-10.jpg";

// Event

import EventPackageImageFirst from "../img/shoots/love/M&P-20.jpg";
import EventPackageImageSecond from "../img/shoots/Evelien/A-22.jpg";
import EventPackageImageThird from "../img/shoots/Kees/A-14.jpg";

export const packageDetails = [
  {
    name: "Standaard",
    description: [
      "20 bewerkte foto's",
      "Alle foto's digitaal verzonden",
      "Shoot van 1 uur",
      "Locatie naar keuze",
    ],
    image: PackageImageFirst,
    price: 170,
    hasActiveDeal: { active: false, newPrice: 120 },
  },
  {
    name: "Comfort",
    description: [
      "30 bewerkte foto's",
      "Alle foto's digitaal verzonden",
      "Shoot van 1 uur",
      "Locatie naar keuze",
      "10% korting voor een volgende shoot",
      "Fotoboek",
    ],
    image: PackageImageSecond,
    price: 250,
    hasActiveDeal: { active: false, newPrice: 0 },
  },
  {
    name: "Premium",
    description: [
      "40 bewerkte foto's",
      "Alle foto's digitaal verzonden",
      "Shoot van 1 uur",
      "Locatie naar keuze",
      "10% korting voor een volgende shoot",
      "Fotoboek",
      "20% korting op een tweede fotoboek",
    ],
    image: PackageImageThird,
    price: 350,
    hasActiveDeal: { active: false, newPrice: 0 },
  },
];

export const eventPackageDetails = [
  {
    name: "Standaard",
    description: [
      "Alle bewerkte foto's",
      "Alle foto's digitaal verzonden",
      "Shoot tot gewenste tijd",
      "Tot 2 locaties naar keuze",
    ],
    image: EventPackageImageFirst,
    price: 450,
    hasActiveDeal: { active: false, newPrice: 120 },
  },
  {
    name: "Comfort",
    description: [
      "Alle bewerkte foto's",
      "Alle foto's digitaal verzonden",
      "Shoot tot gewenste tijd",
      "Tot 2 locaties naar keuze",
      "20% korting voor een volgende shoot",
      "Fotoboek",
    ],
    image: EventPackageImageSecond,
    price: 600,
    hasActiveDeal: { active: false, newPrice: 0 },
  },
  {
    name: "Premium",
    description: [
      "Alle bewerkte foto's",
      "Alle foto's digitaal verzonden",
      "Shoot tot gewenste tijd",
      "Tot 2 locaties naar keuze",
      "25% korting voor een volgende shoot",
      "Fotoboek",
      "20% korting op een tweede fotoboek",
    ],
    image: EventPackageImageThird,
    price: 750,
    hasActiveDeal: { active: false, newPrice: 0 },
  },
];
