import React from "react";
import PriceCard from "../components/PriceCard";
import { packageDetails, eventPackageDetails } from "../data/PackageDetails";
import { Link } from "react-router-dom";
import { scroll } from "../helperFunctions/scroller";

const Products = () => {
  return (
    <>
      <div className="px-4 py-8 mx-auto items-center sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
        <div className="grid max-w-md gap-10 row-gap-8 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto  mx-auto">
          {packageDetails.map((packageCard) => (
            <PriceCard data={packageCard} />
          ))}
        </div>

        <Link onClick={() => scroll("top")} to={"/photo-book"}>
          <div className="mx-auto text-center mt-5 flex items-center justify-center">
            <button className="btn bg-yellow-600 hover:bg-yellow-700">
              Bekijk Fotoboek
            </button>
          </div>
        </Link>
      </div>

      <div className="text-center font-title text-5xl md:text-6xl lg:text-7xl mt-4">
        Speciale Evenementen
      </div>
      <p className="text-center p-4">
        De shoots worden alleen buiten gehouden. <br />
        Alle prijzen zijn exclusief reiskosten. <br /> Naar wens kan ook tijdens
        de evenementen gefotografeerd worden exclusief video. (tegen een
        meerprijs)
      </p>
      <div className="px-4 py-16 mx-auto items-center sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
        <div className="grid max-w-md gap-10 row-gap-8 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto mx-auto">
          {eventPackageDetails.map((packageCard) => (
            <PriceCard data={packageCard} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Products;
