import React from "react";
import {FloatButton} from "antd";
import { GrPlan } from "react-icons/gr";

const Salonized = () => {
  return (
      <FloatButton
          shape={'square'}
          type={'text'}
          icon={<GrPlan />}
          className="bg-yellow-600 cursor-pointer text-accent font-text font-bold hover:scale-110 transition"
          style={{
            right: 20,

          }}
          href="https://moonlight-photography.salonized.com/widget_bookings/new"
          target={'_blank'}
          rel={'noopener noreferrer'}
      />
  );
};

export default Salonized;
