import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import AnimRoutes from "./components/AnimRoutes";
import Salonized from "./components/salonized";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <AnimRoutes />
        <Salonized />
        <Footer />
      </Router>
    </>
  );
};

export default App;
