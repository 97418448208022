import { scroller, animateScroll } from "react-scroll";

export const scroll = (element) => {
  const options = { smooth: true, duration: 500 };

  // Need to wait for page to render
  setTimeout(() => {
    element === "top"
      ? animateScroll.scrollToTop(options)
      : scroller.scrollTo(element, options);
  }, 100);
};
