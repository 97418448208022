import React from "react";
import ToestemmingVerklaring from "../pdf/TOESTEMMINGSVERKLARING.pdf";

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-6">
          Privacybeleid
        </h1>
        <p className="mb-6 text-gray-700">
          Uw privacy is belangrijk voor ons. Dit privacybeleid legt uit welke
          persoonlijke gegevens we verwerken, hoe we deze verwerken en voor
          welke doeleinden.
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Informatie die we verzamelen
          </h2>
          <p className="text-gray-700 mb-4">
            We verzamelen informatie om betere diensten aan al onze gebruikers
            te bieden. Dit kan het volgende omvatten:
          </p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Informatie die u ons direct geeft.</li>
            <li>
              Informatie die we krijgen door uw gebruik van onze diensten.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Hoe we informatie gebruiken
          </h2>
          <p className="text-gray-700 mb-4">
            We gebruiken de informatie die we verzamelen voor de volgende
            doeleinden:
          </p>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Om onze diensten te leveren, te onderhouden en te verbeteren.
            </li>
            <li>Om nieuwe diensten te ontwikkelen.</li>
            <li>Om onze diensten voor u te personaliseren.</li>
            <li>Om op maat gemaakte inhoud en aanbevelingen te bieden.</li>
            <li>Om u updates, berichten en andere informatie te sturen.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Gegevensbeveiliging
          </h2>
          <p className="text-gray-700">
            We implementeren passende technische en organisatorische maatregelen
            om een niveau van beveiliging te waarborgen dat past bij het risico
            van het verwerken van uw persoonlijke gegevens.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Toestemingverklaring
          </h2>
          <p className="text-gray-700">
            Hier kun je het{" "}
            <a
              href={ToestemmingVerklaring}
              className="text-yellow-500 hover:text-yellow-600"
            >
              formulier
            </a>{" "}
            downloaden en verzenden voor het gebruik van een van onze services,
            en je kunt het verzenden naar{" "}
            <a
              className="text-yellow-500 hover:text-yellow-600"
              href="mailto:info@moonlightphoto.nl"
            >
              info@moonlightphoto.nl
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
