import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { scroll } from "../helperFunctions/scroller";
import Cover from "../img/about/cover.jpg";
import { Divider } from "antd";

const About = () => {
  return (
    <div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <img
            src={Cover}
            alt="Profile"
            className="mx-auto h-40 w-40 rounded-full object-cover mb-6 shadow-lg"
          />
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
            Ayse acikgoz dagli
          </h1>
          <p className="text-lg text-gray-700 mb-6">
            Hallo! Ik ben Ayse Acikgoz Dagli, een gepassioneerde fotografe die
            gespecialiseerd is in het vastleggen van de mooiste momenten in het
            leven. Met 3 jaar ervaring en een fotografie cursus, streef ik
            ernaar om tijdloze en adembenemende beelden te creëren.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            Moeder van 2 kinderen, 30 jaar, Nederland/Rotterdam, Bergen op Zoom.
          </p>
        </div>

        <Divider />

        <div>
          <section className="mb-12 mt-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Fotografie Stijlen
            </h2>
            <p className="text-lg text-gray-700 mb-4">
              Ik specialiseer me in verschillende fotografiestijlen, waaronder:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Liefde</li>
              <li>Zwangerschap</li>
              <li>Familie</li>
              <li>Vintage stijl</li>
              <li>Boho stijl</li>
            </ul>
            <p className="text-lg text-gray-700 mb-4">
              Mijn doel is om de unieke schoonheid in elk onderwerp en elke
              scène die ik vastleg naar voren te brengen.
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Henna</li>
              <li>Civiele bruiloft</li>
              <li>Verloving</li>
            </ul>
          </section>
        </div>

        <Divider />

        <section>
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Contact</h2>
          <p className="text-lg text-gray-700 mb-4">
            Geïnteresseerd om samen te werken of heeft u vragen? Neem gerust
            contact op!
          </p>

          <RouterLink
            to={"/"}
            className="btn bg-yellow-600 hover:bg-yellow-700 w-[230px]"
            onClick={() => {
              scroll("contact");
            }}
          >
            Neem contact
          </RouterLink>
        </section>
      </div>
    </div>
  );
};

export default About;
