import React from "react";
import loveShootCover from "../img/shoots/Kees/A-5.jpg";
import pregnancyShootCover from "../img/shoots/tris/1.jpg";
import pregnancyShootCover2 from "../img/shoots/Zwangerschap/Mariah-22.jpg";
import thirdCoverImage from "../img/shoots/Danielle/Z-13.jpg";
import { Element } from "react-scroll";
import { scroll } from "../helperFunctions/scroller";

import { Link as RouterLink } from "react-router-dom";

const Services = () => {
  return (
    <>
      <div className="w-full text-center mt-12">
        <h1 className="text-gray-900 lg:text-8xl md:text-7xl xs:text-6xl font-title">
          Services
        </h1>
      </div>
      <Element name="services">
        <div className="mt-4 flex flex-wrap ">
          <div className="w-full md:w-1/2 xl:w-1/3 px-2">
            <div className="bg-white rounded-lg overflow-hidden mb-10">
              <img src={loveShootCover} alt="image" className="w-full" />
              <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                <h3>
                  <a
                    href="javascript:void(0)"
                    className="
                          font-semibold
                          text-dark text-xl
                          sm:text-[22px]
                          md:text-xl
                          lg:text-[22px]
                          xl:text-xl
                          2xl:text-[22px]
                          mb-4
                          block
                          hover:text-primary
                          font-text
                        "
                  >
                    Love shoots
                  </a>
                </h3>
                <p className="text-base text-body-color leading-relaxed mb-7 font-text">
                  Leg de magie van jullie liefde vast met een romantische love
                  shoot. Mijn portfolio toont intieme momenten en oprechte
                  emoties in tijdloze beelden.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 px-2">
            <div className="bg-white rounded-lg overflow-hidden">
              <img
                src={pregnancyShootCover}
                alt="Pregnancy shoot cover"
                className="w-full"
              />
              <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                <h3>
                  <a
                    href="javascript:void(0)"
                    className="
                          font-semibold
                          text-dark text-xl
                          sm:text-[22px]
                          md:text-xl
                          lg:text-[22px]
                          xl:text-xl
                          2xl:text-[22px]
                          mb-4
                          block
                          hover:text-primary
                          font-text
                        "
                  >
                    Zwangerschap Shoots
                  </a>
                </h3>
                <p className="text-base text-body-color leading-relaxed font-text">
                  Vereeuwig de magie van je zwangerschap met een betoverende
                  fotoshoot. Mijn portfolio vangt de schoonheid en het wonder
                  van deze bijzondere tijd in tijdloze beelden.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 px-2">
            <div className="bg-white rounded-lg overflow-hidden">
              <img src={thirdCoverImage} alt="image" className="w-full" />
              <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                <h3>
                  <a
                    href="javascript:void(0)"
                    className="
                          font-semibold
                          text-dark text-xl
                          sm:text-[22px]
                          md:text-xl
                          lg:text-[22px]
                          xl:text-xl
                          2xl:text-[22px]
                          mb-4
                          block
                          hover:text-primary
                          font-text
                        "
                  >
                    Evenementen Shoots
                  </a>
                </h3>
                <p className="text-base text-body-color leading-relaxed mb-7 font-text">
                  Vang de essentie van uw speciale evenement met mijn
                  professionele fotoshoots. Mijn portfolio toont onvergetelijke
                  momenten in prachtige beelden.
                </p>
              </div>
            </div>
          </div>
        </div>
        <RouterLink
          to={"/products"}
          className="btn bg-yellow-600 hover:bg-yellow-700 w-80 text-center mx-auto"
          onClick={() => scroll("top")}
        >
          Bekijk alle pakketen
        </RouterLink>
      </Element>
    </>
  );
};

export default Services;
