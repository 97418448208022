import React from "react";
import visionCover from "../img/shoots/ermesya/ermesya_01.jpg";
import inspirationCover from "../img/shoots/ermesya/ermesya_02.jpg";
import { Divider } from "antd";

const Philosophy = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
            Mijn Philosophy
          </h1>
          <p className="text-lg text-gray-700">
            Fotografie is meer dan alleen het vastleggen van momenten; het gaat
            om het creëren van kunst die een verhaal vertelt. Mijn filosofie is
            geworteld in de overtuiging dat elke foto de kracht heeft om emoties
            op te roepen, te inspireren en de essentie van het onderwerp over te
            brengen.
          </p>
        </div>

        <section className="mb-12">
          <div className="flex flex-col md:flex-row items-center">
            <div className="mb-6 md:mb-0 md:mr-6">
              <img
                src={visionCover}
                alt="Vision"
                className="w-[500px] object-cover rounded-lg shadow-md"
              />
            </div>
            <div className="md:w-1/2">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Visie</h2>
              <p className="text-lg text-gray-700">
                Mijn visie is om de schoonheid en complexiteit van de wereld
                vast te leggen door mijn lens. Ik streef ernaar om beelden te
                creëren die niet alleen visueel verbluffend zijn, maar ook diep
                betekenisvol. Elke foto is een stukje van mijn perspectief, een
                glimp van hoe ik de wereld zie.
              </p>
            </div>
          </div>
        </section>

        <Divider />

        <section className="mb-12">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-6 md:mb-0 md:mr-6 order-2 md:order-1">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Inspiratie
              </h2>
              <p className="text-lg text-gray-700">
                Ik haal inspiratie uit de natuurlijke wereld, menselijke emoties
                en de subtiele interacties die vaak onopgemerkt blijven. Elke
                dag biedt nieuwe kansen om schoonheid in het alledaagse te
                vinden, en mijn doel is om deze vluchtige momenten vast te
                leggen.
              </p>
            </div>
            <div className="order-1 md:order-2">
              <img
                src={inspirationCover}
                alt="Inspiration"
                className="w-[500px] h-full object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </section>

        <section className="text-center">
          <blockquote className="text-2xl italic font-semibold text-gray-900 mb-4">
            "Photography is the story I fail to put into words." - Destin Sparks
          </blockquote>
          <p className="text-lg text-gray-700">
            Deze quote resoneert diep met mijn benadering van fotografie. Elke
            klik van de sluiter is een poging om vast te leggen wat woorden vaak
            niet kunnen uitdrukken.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Philosophy;
