import React from "react";
import loveCoverImage from "../img/shoots/F-14.jpg";
import familyCoverImage from "../img/shoots/Danielle/LOVE-54.jpg";
import { Image } from "antd";
import { Link as RouterLink } from "react-router-dom";
import pregnancyCoverImage from "../img/shoots/Zwangerschap/Mariah-14.jpg";
import { Element } from "react-scroll";
import { scroll } from "../helperFunctions/scroller";

const Portfolio = () => {
  return (
    <Element name="portfolio">
      <div className="mt-12 flex flex-col items-center justify-center text-center pb-8 gap-y-8 ">
        <div className="w-full">
          <p className="text-gray-900 lg:text-8xl md:text-7xl xs:text-6xl font-title">
            Portfolio
          </p>
          <p className="mb-4 max-w-sm mx-auto font-text">
            Een gepassioneerde creatieveling met een scherp oog voor detail en
            een liefde voor innovatie. Mijn werk weerspiegelt een combinatie van
            artistieke flair en technische expertise.
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-4 items-center">
          <div className="sm:max-w-[385px] max-w-[350px] max-h-300px  sm:h-[220px] h-[220px] lg:h-[270px] overflow-hidden">
            <Image
              loading="lazy"
              className="object-cover h-full lg:h-[220px] hover:scale-110 transition-transform duration-500"
              src={loveCoverImage}
              alt="Portfolio image"
            />
          </div>

          <div className="sm:max-w-[385px] max-w-[385px] max-h-300px lg:max-w-[520px] sm:h-[220px] h-[220px] lg:h-[270px] overflow-hidden">
            <Image
              loading="lazy"
              className="object-cover h-full lg:h-[220px] hover:scale-110 transition-transform duration-500"
              src={pregnancyCoverImage}
              alt="Portfolio image"
            />
          </div>

          <div className="sm:max-w-[385px] max-w-[350px] max-h-300px lg:max-w-[520px] sm:h-[220px] h-[220px] lg:h-[270px] overflow-hidden">
            <Image
              loading="lazy"
              className="object-cover h-full lg:h-[220px] hover:scale-110 transition-transform duration-500"
              src={familyCoverImage}
              alt="Portfolio image"
            />
          </div>
        </div>

        <RouterLink
          to={"/portfolio"}
          className="btn bg-yellow-600 hover:bg-yellow-700"
          onClick={() => scroll("top")}
        >
          Bekijk alle afbeeldingen
        </RouterLink>
      </div>
    </Element>
  );
};

export default Portfolio;
