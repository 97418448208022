import lizbon1 from "../img/photobook/Lizbon/lizbon_1.jpg";
import lizbon2 from "../img/photobook/Lizbon/lizbon_2.jpg";

import Jasmin1 from "../img/photobook/Jasmin/jasmin_1.jpg";
import Jasmin2 from "../img/photobook/Jasmin/jasmin_2.jpg";

import Liva1 from "../img/photobook/Liva/liva_1.jpg";
import Liva2 from "../img/photobook/Liva/liva_2.jpg";

import Milano1 from "../img/photobook/Milano/milano_1.jpg";
import Milano2 from "../img/photobook/Milano/milano_2.jpg";

import Piramit1 from "../img/photobook/Piramit/piramit_1.jpg";
import Piramit2 from "../img/photobook/Piramit/piramit_2.jpg";

import Titan1 from "../img/photobook/Titan/titan_1.jpg";
import Titan2 from "../img/photobook/Titan/titan_2.jpg";

const photoBookDetails = [
  {
    id: 1,
    name: "Lizbon",
    description: lizbon2,
    formats: ["30 X 80", "30 X 60", "30 X 50", "21 X 54"],
    price: 100,
    image: lizbon1,
  },
  {
    id: 2,
    name: "Jasmin",
    description: Jasmin2,
    formats: ["30 X 80", "21 X 54"],
    price: 100,
    image: Jasmin1,
  },
  {
    id: 3,
    name: "Liva",
    description: Liva2,
    formats: ["30 X 80", "30 X 60", "30 X 50", "21 X 54"],
    price: 100,
    image: Liva1,
  },
  {
    id: 4,
    name: "Milano",
    description: Milano2,
    formats: ["30 X 80", "30 X 50", "21 X 54"],
    price: 100,
    image: Milano1,
  },
  {
    id: 5,
    name: "Piramit",
    description: Piramit2,
    formats: ["30 X 80", "30 X 60", "21 X 54"],
    price: 100,
    image: Piramit1,
  },
  {
    id: 6,
    name: "Titan",
    description: Titan2,
    formats: ["30 X 80", "30 X 60", "21 X 54"],
    price: 100,
    image: Titan1,
  },
];

export const getPhotoBookDetails = (id) => {
  let foundItem = photoBookDetails.find((photoBook) => photoBook.id === id);

  if (!foundItem) {
    console.error(`No photoBookDetails found with id:${id}`);
    return;
  }

  return foundItem;
};
