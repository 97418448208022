import React from "react";
// import pages
import Home from "../pages/Home";
import About from "../pages/About";
import Portfolio from "../pages/Portfolio";
import Products from "../pages/Products";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import Philosophy from "../pages/Philosophy";
import PhotoBook from "../pages/PhotoBook";

// Import route en useLocation hook
import { Routes, Route } from "react-router-dom";

const AnimRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/products" element={<Products />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/philosophy" element={<Philosophy />} />
        <Route path="/photo-book" element={<PhotoBook />} />
      </Routes>
    </>
  );
};

export default AnimRoutes;
