import React from "react";
import { Element } from "react-scroll";
import ContactForm from "./ContactForm";
import Socials from "./Socials";

const Contact = () => {
  return (
    <>
      <div className="py-8 lg:py-16 px-4 w-70 mx-auto">
        <Element name="contact">
          <div className="w-full text-center">
            <h1 className="text-gray-900  lg:text-8xl md:text-7xl xs:text-6xl font-title">
              Contact
            </h1>
          </div>

          <p className="lg:mb-8 md:mb-4 sm:mb-6 font-light text-center text-gray-800 dark:text-gray-600 sm:text-xl">
            Neem gerust contact met ons op voor vragen, samenwerkingen of meer
            informatie. Vul het onderstaande formulier in en we reageren zo snel
            mogelijk!
          </p>
          <div className="flex items-center justify-center">
            <Socials size={30} color={"text-gray-900"} />
          </div>

          <ContactForm />
        </Element>
      </div>
    </>
  );
};

export default Contact;
