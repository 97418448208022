import React, { useState, useEffect } from "react";
import { getPhotoBookDetails } from "../data/PhotoBookDetails";
import { Image } from "antd";

const PhotoBook = () => {
  const [image, setImage] = useState(getPhotoBookDetails(1));

  useEffect(() => {}, [image]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 mb-12">
      <div className="flex flex-col md:flex-row">
        <div className="md:flex-2 mx-auto">
          <div className="text-center md:text-left">
            {image && (
              <h2 className="mb-2 leading-tight tracking-tight font-bold text-gray-800 text-2xl md:text-3xl">
                {image.name}
              </h2>
            )}

            <p className="text-gray-500 text-sm">
              By{" "}
              <a href="#" className="text-indigo-600 hover:underline">
                Moonlight Photography
              </a>
            </p>

            <div className="flex justify-center md:justify-start items-center space-x-4 my-4">
              <div className="rounded-lg bg-gray-100 py-2 px-3">
                <span className="text-gray-900 text mr-1 mt-1">v.a</span>
                <span className="text-gray-900 mr-1 mt-1">€</span>
                <span className="font-bold text-gray-900 text-3xl">
                  {image.price}
                </span>
              </div>
            </div>
          </div>

          <div className="rounded flex flex-col md:flex-row mx-auto justify-center gap-4">
            {image && (
              <div className="h-64 md:h-80 rounded-lg mb-4 mx-auto">
                <Image
                  className="h-full object-contain"
                  src={image.image}
                  alt={image.name}
                  width={300}
                  height={300}
                />
              </div>
            )}

            <div className="flex flex-col sm:flex-row mt-12 md:mt-0 mx-auto text-center">
              <div className="flex-none sm:flex-1  md:w-1/2 lg:w-1/3">
                <div className="h-64 md:h-80 rounded-lg">
                  <h1 className="font-title text-3xl">Selectie aan Kleuren:</h1>
                  {image.description && (
                    <Image
                      className="h-full w-full object-contain"
                      src={image.description}
                      alt="Description"
                      width={300}
                      height={200}
                    />
                  )}
                  <div className="flex-none sm:flex-1 md:flex-grow text-center">
                    <h1 className="font-title text-3xl">
                      Selectie aan formaten:
                    </h1>
                    <ul className="list-disc list-inside">
                      {image.formats &&
                        image.formats.map((format) => <li>{format}</li>)}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -mx-2 xs:mt-36 sm:mt-36">
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <div key={i} className="w-1/2 sm:w-1/3 lg:w-1/6 px-2 mb-4 mt-2">
                <button
                  onClick={() => setImage(getPhotoBookDetails(i))}
                  className={`focus:outline-none rounded-lg h-24 md:h-20 w-full bg-gray-100 flex items-center justify-center ${
                    image === i ? "ring-2 ring-indigo-300 ring-inset" : ""
                  }`}
                >
                  <span className="text-2xl">{i}</span>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoBook;
